<template>
  <div class="data_container">
    <div class="crumbs data_item">
      <el-breadcrumb separator-icon="ArrowRight" v-if="!quote">
        <el-breadcrumb-item :to="{ path: '/workbench' }">
          <i class="iconfont icon-zhuye"></i
        ></el-breadcrumb-item>
        <el-breadcrumb-item>数据维护</el-breadcrumb-item>
        <el-breadcrumb-item>因素系数</el-breadcrumb-item>
      </el-breadcrumb>

      <el-breadcrumb separator-icon="ArrowRight" v-else>
        <el-breadcrumb-item :to="{ path: '/workbench' }">
          <i class="iconfont icon-zhuye"></i
        ></el-breadcrumb-item>
        <el-breadcrumb-item>数据维护</el-breadcrumb-item>
        <el-breadcrumb-item @click="quote = false" style="cursor: pointer"
          >因素系数</el-breadcrumb-item
        >
        <el-breadcrumb-item>引用模板</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="data_item flex_between" v-if="!quote">
      <div class="flex_start operation">
        <el-select v-model="typeVal" class="select_day" placeholder="Select">
          <el-option
            v-for="item in typeList.data"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <div class="flex_start">
          <span>任务日期：</span>
          <el-date-picker
            v-model="dateTime"
            type="date"
            placeholder="请选择"
            @change="changeType"
          />
        </div>
      </div>

      <div class="flex_start">
        <el-button class="data_btns flex_center" @click="clickQuote">
          <i class="icon iconfont icon-moban"></i>
          <span>引用模板</span>
        </el-button>

        <el-button class="data_btns flex_center">
          <i class="icon iconfont icon-daochu"></i>
          <span>数据状态</span>
        </el-button>
      </div>
    </div>

    <div class="data_item flex_between" v-else>
      <div class="flex_start operation">
        <span>引用系数：</span>
        <el-radio-group v-model="coefficient" class="coefficient">
          <el-radio-button
            :label="item.value"
            v-for="item in coefficientList.data"
            :key="item.value"
            >{{ item.label }}</el-radio-button
          >
        </el-radio-group>
      </div>
      <div class="flex_start">
        <el-input-number
          class="percentage"
          v-model="countNum"
          :min="1"
          :max="100"
          @change="handleChange"
        />
      </div>
    </div>

    <div class="data_box">
      <el-table
        :data="tableData.data"
        :header-cell-style="{
          background: '#022C63',
          height: '60px',
          color: '#FFFFFF',
        }"
        :cell-style="{
          background: '#0B183C',
          padding: '15px 0',
          color: '#FFFFFF',
        }"
        class="forecast_table"
      >
        <template v-for="(tit, titIndex) in tableTitle.data" :key="titIndex">
          <el-table-column
            :prop="tit"
            :label="tit"
            align="center"
            :width="titIndex == 0 ? 120 : ''"
          />
        </template>
      </el-table>

      <div class="flex_end">
        <el-pagination
          :page-size="20"
          :pager-count="6"
          layout="prev, pager, next"
          :total="1000"
          class="pages"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, getCurrentInstance, nextTick, onMounted } from "vue";
export default {
  name: "dataImport",
  setup() {
    const { proxy } = getCurrentInstance(); // proxy是组件实例化对象

    let dateTime = ref(new Date());

    const changeType = (val) => {};
    // dataType 0 未导入  1 未完整  2 完整
    let forecastList = reactive({
      data: [
        {
          id: 1,
          label: "负荷预测信息",
          date: "2022.08.09   11:00PM",
          num: 1,
          dataType: 2,
          update: "2022.08.09   11:00PM",
        },
        {
          id: 1,
          label: "备用预测信息",
          date: "2022.08.09   11:00PM",
          num: 1,
          dataType: 0,
          update: "2022.08.09   11:00PM",
        },
        {
          id: 1,
          label: "必开必停机组预测信息",
          date: "2022.08.09   11:00PM",
          num: 1,
          dataType: 2,
          update: "2022.08.09   11:00PM",
        },
        {
          id: 1,
          label: "机组检修预测信息",
          date: "2022.08.09   11:00PM",
          num: 1,
          dataType: 2,
          update: "2022.08.09   11:00PM",
        },
        {
          id: 1,
          label: "阻塞预测信息",
          date: "2022.08.09   11:00PM",
          num: 1,
          dataType: 1,
          update: "2022.08.09   11:00PM",
        },
      ],
    });

    let typeList = reactive({
      data: [
        { label: "电网运行预测信息", value: 1 },
        { label: "电网运行实际信息", value: 2 },
      ],
    });
    let typeVal = ref(1);

    let tableTitle = reactive({ data: ["因素类别"] });

    let tableData = reactive({ data: [] });

    let quote = ref(false);

    let countNum = ref(60);

    let coefficientList = reactive({
      data: [
        { label: "天气因素", value: 1 },
        { label: "订单因素", value: 2 },
        { label: "节假日因素", value: 3 },
        { label: "其他", value: 4 },
      ],
    });

    let coefficient = ref(1);

    const clickQuote = () => {
      quote.value = true;
    };

    onMounted(() => {
      nextTick(() => {
        let num = 0;
        for (let i = 0; i < 24; i++) {
          num = i;
          tableTitle.data.push(num < 10 ? "0" + num + ":00" : num + ":00");
          tableTitle.data.push(num < 10 ? "0" + num + ":15" : num + ":15");
          tableTitle.data.push(num < 10 ? "0" + num + ":30" : num + ":30");
          tableTitle.data.push(num < 10 ? "0" + num + ":45" : num + ":45");
        }
        let arry = tableTitle.data;
        let currentData = [];
        for (let i in arry) {

          let current = {};
          // current[arry[i]]=''
       
          if (i == 0) {
            console.log(coefficientList.data[i].label)
           
            current[arry[i]] =coefficientList.data[i].label;
            currentData[arry[i]] = current[arry[i]];
            console.log( current[arry[i]])
          } else {
            current[arry[i]] = Math.floor(Math.random() * 100) + "%";
            currentData[arry[i]] = current[arry[i]];
          }
        }
        tableData.data.push(currentData);
        tableData.data.push(currentData);
        tableData.data.push(currentData);
        tableData.data.push(currentData);
      //  let datas= JSON.parse(JSON.stringify(currentData))
      //  tableData.data.push(datas);
      //   tableData.data.push(datas);
      //   tableData.data.push(datas);


      //   tableData.data[0]['因素类别']=coefficientList.data[0].label
      //   tableData.data[1]['因素类别']=coefficientList.data[1].label
      //   tableData.data[2]['因素类别']=coefficientList.data[2].label
      //   tableData.data[3]['因素类别']=coefficientList.data[3].label


        // coefficientList.data.forEach((val,index)=>{
        //   let datas=[]
        //   console.log(currentData)
        //   datas['因素类别']=val.label
        //   console.log(datas)
        //   // datas=datas.concat(currentData)
        //   tableData.data.push(datas);
        //   console.log(datas)
        //   // if(index==0) {
        //   //   tableData.data[0]['因素类别']=val.label
        //   // }else{
        //   //   tableData.data[1]['因素类别']=val.label
        //   // }      
        // })
   
   


      });

    });
    return {
      dateTime,
      changeType,
      forecastList,
      typeList,
      typeVal,
      tableTitle,
      tableData,
      quote,
      countNum,
      coefficient,
      coefficientList,
      clickQuote,
    };
  },
};
</script>

<style lang="scss" scoped>
.data_box {
  // display: flex;
  // justify-content: space-between;
  margin-bottom: 15px;
}

.data_title {
  margin: 16px 0;
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-indent: 1em;

  &::before {
    display: block;
    content: "";
    width: 6px;
    height: 20px;
    background: linear-gradient(135deg, #0081ff 0%, #22cce2 100%);
    border-radius: 3px 3px 3px 3px;
  }
}

.data_container {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  .data_item {
    width: 100%;
    margin-bottom: 24px;

    .operation {
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;

      &:deep(.el-input__wrapper) {
        background: #1f2935;
        box-shadow: none;
      }
      &:deep(.el-input__inner) {
        color: #ffffff;
      }
      &:deep(.select_day) {
        width: 180px;
        margin-right: 32px;
      }
      &:deep(.el-select .el-input__wrapper) {
        background: #1f2935;
        box-shadow: none;
      }
    }
  }
  .crumbs {
    font-size: 14px;
    &:deep(.el-breadcrumb__item:last-child .el-breadcrumb__inner) {
      color: #ffffff;
    }
  }
}

.forecast_table {
  width: 100%;
  background: #0b183c;

  border-radius: 8px;
  --el-table-border: 2px solid #090a1f;
  --el-table-tr-bg-color: transparent;

  &:deep(.el-table__header) {
    padding: 0px 10px;
    box-shadow: inset 0px 0px 10px 0px #3767ad;
  }
  &:deep(.el-scrollbar) {
    padding: 0 10px 15px;
    box-shadow: inset 0px 0px 10px 0px #3767ad;
  }
  &:deep(.el-table__inner-wrapper::before) {
    background: #090a1f;
  }
}
.tag_box {
  min-width: 70px;
}
.tag {
  width: 10px;
  height: 10px;
  background: #ff8a48;
  margin-right: 8px;
  border-radius: 50%;
  display: inline-block;
}
.tag_green {
  width: 10px;
  height: 10px;
  background: #09b66d;
  margin-right: 8px;
  border-radius: 50%;
  display: inline-block;
}
.tag_blue {
  width: 10px;
  height: 10px;
  background: #22cce2;
  margin-right: 8px;
  border-radius: 50%;
  display: inline-block;
}
.data_btns {
  background: linear-gradient(180deg, #3090e4 0%, #09d3d5 100%);
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  margin-right: 15px;
  border: none;
  &:deep(.icon) {
    padding-right: 10px;
  }
}
.pages {
  margin-top: 25px;
  padding: 4px 0;
  background-color: #1f2935;
  --el-pagination-bg-color: #1f2935;
  border-radius: 6px;
  color: #ffffff;
  &:deep(button:disabled) {
    background-color: #1f2935;
  }
  &:deep(.el-pager li.is-active) {
    color: #ffffff;
    background: #0081ff;
    border-radius: 8px;
    margin: 0;
  }
  &:deep(.btn-prev) {
    color: #ffffff;
  }
  &:deep(.btn-next) {
    color: #ffffff;
  }
  &:deep(.btn-quickprev) {
    color: #ffffff;
  }
  &:deep(.btn-quicknext) {
    color: #ffffff;
  }
}
.coefficient {
  &:deep(.el-radio-button__inner) {
    color: #ffffff;
    border: 1px solid #8291a9;
    background: #8291a9;
  }
  &:deep(.is-active .el-radio-button__inner) {
    background: #409eff;
  }

  //    &:deep(.is-active){
  //   background: #0081FF;
  //  }
}
.percentage {
  &:deep(.el-input__wrapper) {
    background: #8291a9;
    box-shadow: none;
    .el-input__inner {
      color: #ffffff;
    }
  }
  &:deep(.el-input-number__decrease) {
    background: linear-gradient(180deg, #3090e4 0%, #09d3d5 100%);
    color: #ffffff;
  }
  &:deep(.el-input-number__increase) {
    background: linear-gradient(180deg, #3090e4 0%, #09d3d5 100%);
    color: #ffffff;
  }
}
</style>
